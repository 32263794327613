import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AllergenNamePipe,
  AllQualitySchemes,
  ApiService,
  CheckboxArrayOption,
  CheckboxArrayOptionSimple,
  checkboxTitleComparator,
  Component as Cp,
  COMPONENT_DEFAULTS,
  ComponentNamePipe,
  DEFAULT_COMPONENTS,
  ErrorComponent,
  gs1ValidatorOrEmpty,
  Packaging,
  QualitySchemePipe,
  SubscriptionSink,
  UpdateProductRequest,
  validateFormArrayMinLength
} from '@app/shared';
import { get, omit } from 'lodash-es';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  COMPONENT_DEFAULTS = COMPONENT_DEFAULTS;

  allergenOptions: CheckboxArrayOptionSimple[];

  qualitySchemeOptions: CheckboxArrayOption[];

  form: FormGroup;

  packaging: FormArray;

  specifiedValues: FormArray;

  @ViewChild(ErrorComponent, {static: true})
  errorComponent: ErrorComponent;

  @ViewChild('ingredients', {static: true})
  ingredientsEl: ElementRef<HTMLTextAreaElement>;

  @ViewChild('supplier', {static: true})
  supplierEl: ElementRef<HTMLTextAreaElement>;

  @ViewChild('producer', {static: true})
  producerEl: ElementRef<HTMLTextAreaElement>;

  protected subscription = new SubscriptionSink();

  @Input()
  values?: Omit<UpdateProductRequest, 'id' | 'yearId'>;

  @Output()
  save = new EventEmitter<Omit<UpdateProductRequest, 'id' | 'yearId'>>();

  @Output()
  dirty = new EventEmitter<boolean>();

  constructor(
    protected fb: FormBuilder, protected api: ApiService, allergenName: AllergenNamePipe,
    qualityScheme: QualitySchemePipe, protected componentName: ComponentNamePipe
  ) {

    this.allergenOptions = allergenName.options();

    this.qualitySchemeOptions = AllQualitySchemes.map(code => ({
      title: qualityScheme.transform(code),
      value: code
    })).sort(checkboxTitleComparator);
  }

  protected validateValues(group: FormArray) {
    const chot =  group.value.find((c: any) => c.component === Cp.TotalCarbohydrates)?.value ?? 0;
    const prot =  group.value.find((c: any) => c.component === Cp.Protein)?.value ?? 0;
    const fat =  group.value.find((c: any) => c.component === Cp.Fat)?.value ?? 0;
    const satFattyAcids =  group.value.find((c: any) => c.component === Cp.SaturatedFattyAcids)?.value ?? 0;

    const errors: any = {};

    if (chot + prot + fat > 100) {
      errors.macroSum = true;
    }

    if (satFattyAcids > fat) {
      errors.satFattyAcids = true;
    }

    return errors;
  }

  protected createForm() {
    if (this.form) {
      this.form.markAsUntouched();
      this.form.markAsPristine();
      return;
    }

    this.packaging = this.fb.array([], validateFormArrayMinLength(0));

    this.specifiedValues = this.fb.array(DEFAULT_COMPONENTS.map(component =>
      this.fb.group({
        component: [component],
        value: [null, [Validators.min(0)].concat( Cp.Energy === component ? [Validators.required, Validators.max(900)] : []) ]
      })
    ), {
      validators: this.validateValues.bind(this)
    });

    this.form = this.fb.group({
      name: [null, Validators.required],
      producer: [],
      supplier: [],
      eCatalog: [null, [Validators.required]],
      allergens: this.fb.array(this.allergenOptions.map(() => this.fb.control(null))),
      ingredients: [],
      specifiedValues: this.specifiedValues,
      qualityScheme: [],
      edible: [null, [Validators.min(0), Validators.max(99)]],
      packaging: this.packaging
    });

    this.subscription.sink = this.form.statusChanges
      .subscribe(() => {
        const errors = [];

        if (!this.form.dirty) {
          return;
        }

        if (this.specifiedValues.errors) {
          if (this.specifiedValues.errors.macroSum) {
            errors.push('Skupni ogljikovi hidrati + Skupne beljakovine + Skupne maščobe mora biti manj kot 100g.');
          }
          if (this.specifiedValues.errors.satFattyAcids) {
            errors.push('Vrednost nasičene kisline mora biti nižja kot skupne maščobe.');
          }
        }

        this.errorComponent.error = errors.join('. ');
      });

    this.subscription.sink = this.form.statusChanges.subscribe(() => this.dirty.emit(this.form.dirty));
  }

  protected validatePackageAmount(control: AbstractControl) {
    if (!control.parent || typeof(control.parent.value.divisible) !== 'boolean')  {
      return {};
    }

    return {
      ...Validators.required(control),
      ...Validators.min(1)(control),
    };
  }

  protected createPackageGroup() {
    return this.fb.group({
      id: [],
      divisible: [],
      amount: [null, [this.validatePackageAmount.bind(this)]],
      barcode: [null, [gs1ValidatorOrEmpty]],
      basePrice: [null, [Validators.min(0.01), Validators.required]],
      tax: [null, [Validators.min(0), Validators.required]],
      deductable: [null, [Validators.min(0), Validators.required]],
      active: [],
      sourceId: [null],
    });
  }

  ngOnInit() {
    this.createForm();
  }

  mapToForm(value: {component: Cp, value: number}) {
    const factor = COMPONENT_DEFAULTS.get(value.component).factor;
    if (value.value !== null) {
      value.value *= factor;
    }
    if (typeof(value.value) === 'number' && isFinite(value.value)) {
      value.value = Number(Number(value.value).toFixed(1));
    }
    return value;
  }

  mapFromForm(cp: Cp, value: number) {
    const factor = COMPONENT_DEFAULTS.get(cp).factor;
    if (value !== null) {
      return value / factor;
    }
    return value;
  }

  ngOnChanges() {
    this.createForm();

    let nPackaging: number;

    if (this.values) {
      nPackaging = 0;
      if (this.values.existingPackaging) {
        nPackaging += this.values.existingPackaging.length;
      }
      if (this.values.newPackaging) {
        nPackaging += this.values.newPackaging.length;
      }
    } else {
      nPackaging = 1;
    }

    while (this.packaging.length > nPackaging) {
      this.packaging.removeAt(0);
    }

    while (this.packaging.length < nPackaging) {
      this.packaging.push(this.createPackageGroup());
    }

    if (this.values) {
      this.form.setValue({
        name: this.values.name,
        producer: this.values.producer,
        supplier: this.values.supplier,
        eCatalog: this.values.eCatalog ?? null,
        allergens: this.allergenOptions.map(allergen =>
          this.values.allergens?.includes(allergen.value) ? true : (this.values.traceAllergens?.includes(allergen.value) ? null : false)
        ),
        ingredients: this.values.ingredients,
        specifiedValues: DEFAULT_COMPONENTS.map(component => ({
          component,
          value: get(this.values.specifiedValues.find(([c]) => c === component), 1, null)
        })).map(this.mapToForm.bind(this)),
        qualityScheme: this.values.qualityScheme,
        edible: this.values.edible * 100,
        packaging: (this.values.existingPackaging || []).concat((this.values.newPackaging || []).map(p => ({...p, id: null}))).map(p => ({sourceId: null, ...p}))
      });
      this.form.markAsPristine();
      this.dirty.emit(false);
    } else {
      this.form.reset();
      this.form.patchValue({
        allergens: this.allergenOptions.map(() => false),
        specifiedValues: DEFAULT_COMPONENTS.map(component => ({
          component
        })),
        packaging: [{
          active: true,
          tax: 9.5,
          deductable: 0,
          divisible: false,
        }]
      });
    }
  }

  addPackage() {
    const packaging = (this.form.controls.packaging as FormArray);
    const group = this.createPackageGroup();
    group.patchValue({
      active: true,
      tax: 9.5,
      deductable: 0,
      divisible: false,
    });
    packaging.push(group);
    packaging.markAsDirty();
    this.dirty.emit(true);
  }

  packageCleanAmount(packaging: Packaging) {
    if (packaging.divisible) {
      delete packaging.amount;
    }
    return packaging;
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const value = this.form.value;

    const allergens = this.allergenOptions.map((allergen, idx) => value.allergens[idx] ? allergen.value : null).filter(v => v !== null);
    const traceAllergens = this.allergenOptions.map((allergen, idx) => value.allergens[idx] === null ? allergen.value : null).filter(v => v !== null);

    const request: Omit<UpdateProductRequest, 'id' | 'yearId'> = {
      name: value.name,
      producer: value.producer,
      supplier: value.supplier,
      eCatalog: value.eCatalog,
      allergens,
      traceAllergens,
      ingredients: value.ingredients,
      specifiedValues: value.specifiedValues
        .filter(({ value: v }) => v !== null)
        .map(({ component, value: v }) =>
          [component, this.mapFromForm(component, v)]
        ),
      qualityScheme: value.qualityScheme,
      newPackaging: value.packaging.filter(p => !p.id).map(p => omit(p, 'id')).map(this.packageCleanAmount.bind(this)),
      edible: value.edible / 100,
      existingPackaging: [],
    };

    if (this.values) {
      request.existingPackaging = value.packaging.filter(p => !!p.id).map(this.packageCleanAmount.bind(this));
    }

    this.save.emit(request);
  }

  getComponentLabel(index: number) {
    const component = this.form.value.specifiedValues[index].component;
    if (COMPONENT_DEFAULTS.get(component).label) {
      return COMPONENT_DEFAULTS.get(component).label;
    }
    return this.componentName.transform(component);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setEdible(value: number) {
    this.form.get('edible').setValue(value);
    this.form.get('edible').markAsDirty();
    this.dirty.emit(true);
  }

  ngAfterViewChecked() {
    let natEl = this.ingredientsEl.nativeElement;
    natEl.style.height = 'auto';
    natEl.style.height = natEl.scrollHeight + 'px';

    natEl = this.producerEl.nativeElement;
    natEl.style.height = 'auto';
    natEl.style.height = natEl.scrollHeight + 'px';

    natEl = this.supplierEl.nativeElement;
    natEl.style.height = 'auto';
    natEl.style.height = natEl.scrollHeight + 'px';
  }

  removePackage(packageIndex: number) {
    const packaging = (this.form.controls.packaging as FormArray);
    packaging.removeAt(packageIndex);
  }
}
